svg {
  margin-right: 5px;
}

.border-left-primary {
  border-left: 0.25rem solid #4e73df !important;
}
.border-bottom-primary {
  border-bottom: 0.25rem solid #4e73df !important;
}
.border-left-secondary {
  border-left: 0.25rem solid #858796 !important;
}
.border-bottom-secondary {
  border-bottom: 0.25rem solid #858796 !important;
}
.border-left-success {
  border-left: 0.25rem solid #1cc88a !important;
}
.border-bottom-success {
  border-bottom: 0.25rem solid #1cc88a !important;
}
.border-left-info {
  border-left: 0.25rem solid #36b9cc !important;
}
.border-bottom-info {
  border-bottom: 0.25rem solid #36b9cc !important;
}
.border-left-warning {
  border-left: 0.25rem solid #f6c23e !important;
}
.border-bottom-warning {
  border-bottom: 0.25rem solid #f6c23e !important;
}
.border-left-danger {
  border-left: 0.25rem solid #e74a3b !important;
}
.border-bottom-danger {
  border-bottom: 0.25rem solid #e74a3b !important;
}
.border-left-light {
  border-left: 0.25rem solid #f8f9fc !important;
}
.border-bottom-light {
  border-bottom: 0.25rem solid #f8f9fc !important;
}
.border-left-dark {
  border-left: 0.25rem solid #5a5c69 !important;
}
.border-bottom-dark {
  border-bottom: 0.25rem solid #5a5c69 !important;
}

@media (min-height: 600px) {
  .table-responsive {
    height: 37vh;
  }
}

@media (min-height: 700px) {
  .table-responsive {
    height: 58vh;
  }
}

@media (min-height: 800px) {
  .table-responsive {
    height: 62vh;
  }
}

@media (min-height: 900px) {
  .table-responsive {
    height: 67vh;
  }
}

@media (min-height: 1000px) {
  .table-responsive {
    height: 70vh;
  }
}

@media (min-height: 1100px) {
  .table-responsive {
    height: 72vh;
  }
}

@media (min-height: 1200px) {
  .table-responsive {
    height: 75vh;
  }
}
